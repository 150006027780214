@import "@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";
@import "~@ng-select/ng-select/themes/default.theme.css";

.LoaderImage {
  position: fixed;
  left: 50%;
  right: 50%;
  margin: 0 auto;
  top: 30%;
  bottom: 50%;
  z-index: 99999;
}

body {
  font-size: 14px;
}

.modal-header {
  background: #1976d2;
  color: white;
}

.modal {
  z-index: 9999;
}

.tdImg {
  -webkit-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
  padding: 5px 8px;
  cursor: pointer;
  border-radius: 25px;
  color: black;
}

td {
  text-align: center;
}

.dataTables_wrapper {
  background-color: #eff3f8 !important;
  padding: 8px 2px;
}

.modal {
  z-index: 9999999;
}

thead tr {
  background: #1976d2;
  color: #eff3f8;
}

button.dt-button {
  background-color: #1976d2 !important;
  color: #eff3f8 !important;
  background-image: none !important;
  border: 2px solid #17a2b8 !important;
}

/* main.col-md-10{
    padding-left: 0px;
} */
.list-group {
  border-right: 1px solid #ccc;
  box-shadow: 6px 0 6px rgba(0, 0, 0, 0.1);
}

.container_radio {
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.container_radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.container_radio:hover input~.checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.container_radio input:checked~.checkmark {
  background-color: #1976d2;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container_radio input:checked~.checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container_radio .checkmark:after {
  top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #605c5c;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: grey;
}

.container_radio {
  margin-right: 10px;
}

input[type="file"] {
  width: 100%;
}

.formStyle {
  /* box-shadow: 0px 12px 22px 1px #333; */
  margin-bottom: 15px !important;
}

.nonEditable .zs_field {
  pointer-events: none;
}

.formStyle .nonEditable .zs_field .btn {
  background-color: #e1dcda4f;
  background-color: #dad2d2 !important;
  border: none !important;
}

.nonEditable .zs_field .btn {
  background-color: #e1dcda4f;
  background-color: #dad2d2 !important;
  border: none !important;
}

.notFound {
  color: #3c89a2;
}

.not-allowed {
  cursor: not-allowed;
}

.c-token {
  margin-bottom: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #605c5c;
}

.tableStyle td,
.tableStyle th {
  padding: 3px !important;
}

/* The container_checkbox */
.container_checkbox {
  display: block;
  margin-top: 5px;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  float: right;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container_checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom checkbox */
.checkmark_checkbox {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.container_checkbox:hover input~.checkmark_checkbox {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container_checkbox input:checked~.checkmark_checkbox {
  background-color: #2196f3;
}

/* Create the checkmark_checkbox/indicator (hidden when not checked) */
.checkmark_checkbox:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark_checkbox when checked */
.container_checkbox input:checked~.checkmark_checkbox:after {
  display: block;
}

/* Style the checkmark_checkbox/indicator */
.container_checkbox .checkmark_checkbox:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

#eng,
#ger,
#fre,
#ita,
#spa {
  background-color: #fff !important;
  color: black !important;
  font-weight: 400;
}

.swal-overlay {
  z-index: 9999999;
}

.tab-content {
  padding: 10px;
}

.mL10 {
  margin-left: 10px;
}

.redFont {
  color: #de0c0c;
}

thead {
  text-align: center;
}

.formStyle {
  border-radius: 10px;
}

.hovStyle .nav-item {
  color: #1a2326;
  border: 1px solid #43b5ef;
  margin: 0;
  padding: 2px;
  position: relative;
  text-decoration: none;
  transition: all 0.3s;
  cursor: pointer;
}

.hovStyle .nav-item:hover {
  box-shadow: 0 8px 8px rgba(101, 161, 252, 0.24), 0 0 8px rgba(1, 67, 163, 0.12), 0 6px 18px rgba(43, 133, 231, 0.12);
  -webkit-transform: translateY(-2px);
  transform: translateY(-2px);
}

.hovStyle .navLiLast:hover {
  box-shadow: none;
  -webkit-transform: none;
  transform: none;
}

.hovStyle {
  list-style: none;
  width: 100%;
  padding-left: 20px;
  border-right: 1px solid #ccc;
  background-color: rgb(255, 255, 255);
}

.imgICon {
  height: 100px;
  width: 100px;
  margin: 5px;
  cursor: pointer;
}

.unfilled {
  border: 1px solid #ab0a0adb;
  background-color: #ffeeee;
}

.unfilled1 {
  border: 1px solid #ab0a0adb;
  background-color: #ffeeee;
}

.noPadding {
  padding: 0px;
}

.tab-content {
  border: 2px solid #eae2e2;
  border-top: none;
}

.td-disabled {
  pointer-events: none;
}

.checkbox-td {
  text-align: center;
  width: 70px;
}

.checkbox-td label {
  display: initial !important;
  float: none !important;
}


.tox-statusbar {
  display: none !important;
}

.tox .tox-statusbar {
  border-top: 0px !important
}

.tox-statusbar__text-container {
  display: none !important;
}

.tox-notifications-container {
  display: none !important;
}


.mce-notification {
  display: none !important;

}

.e-content {
  margin-top: 27px !important;
}


/* mr checkbox */

.container_mr_checkbox {
  margin-top: 1.8rem;
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container_mr_checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.container_mr_checkbox:hover input~.checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container_mr_checkbox input:checked~.checkmark {
  background-color: #2196F3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container_mr_checkbox input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container_mr_checkbox .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.editor-preview-label {
  text-align: center;
  vertical-align: middle;
  margin-right: 10px;
}

.editor-checkbox {
  height: 20px;
  width: 20px;
  vertical-align: bottom;
}

.cdk-overlay-container {
  z-index: 9999999;
}

#edit-container {
  width: 100%;
  height: 150px;
  overflow-y:auto;
}

#edit-container .NgxEditor__MenuBar {
  display: flex;
  flex-wrap: wrap;
  padding: 7px;
  cursor: default;
  background-color: #fafafa;
  border: 1px solid #cccccc;
  border-bottom: none;
}

.information-button {
  position: relative;
  font-size: 1rem;
}

.hovercard {
  border-radius:20px 20px 20px 3px;
  padding: 7px 13px 7px 13px;
  background-color: #ffffff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  position: absolute;
  min-width: 500px;
  top: -6vh;
  left: 14vw;
  opacity: 0;
  z-index: 9999;
  transform: translateX(-50%);
}

.information-button i:hover + .hovercard  {
  opacity: 1;
  transition: 0.5s;
  transition-delay: 0.1s;
}

.tooltiptext {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #0366d6;
  padding: 18px;
  border-radius: 5px;
  color: white;
  line-height: 15px;
  transition: 1s;
}

#edit-container .NgxEditor {
  background: white;
  color: #000;
  background-clip: padding-box;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, .2);
  position: relative;
  height: fit-content;
  min-height: 105px;
}

.cohort-disabled{
  pointer-events: none;
}

.cohort-disabled .btn{
  background-color: #e1dcda4f;
  background-color: #dad2d2 !important;
  border: none !important;
}
